import { StepId } from '../../consts/assistant-steps';
import {
  FormField,
  FormMultipleChoiceConfig,
  MultipleChoiceOption,
} from '../../interfaces/step-definition';

export function getFieldOptions(
  name: string,
  fields: FormField[],
): MultipleChoiceOption[] {
  const field = fields.find(f => name === f.name);
  if (!field) {
    return [];
  }

  return (field.config as FormMultipleChoiceConfig).options;
}

export function getOptionLabelString(
  stepId: StepId | string,
  fieldName: string,
  optionName: string,
) {
  return `${stepId}.formFields.${fieldName}.options.${optionName}.label`;
}

export function getOptionDescriptionString(
  stepId: StepId | string,
  fieldName: string,
  optionName: string,
) {
  return `${stepId}.formFields.${fieldName}.options.${optionName}.description`;
}
