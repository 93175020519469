import { onMounted, onUnmounted } from '@vue/composition-api';
import debounce from 'lodash.debounce';

const ACTIVE_STEP_CLASS = 'v-stepper__step--active';
const scrollToStepTop = debounce(() => {
  document &&
    document
      ?.querySelector(`.${ACTIVE_STEP_CLASS}`)
      ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
}, 200);

const removeScrollListener = () => {
  window.removeEventListener('scroll', scrollToStepTop);
};

export function useStepTopScroller(): void {
  onMounted(() => {
    scrollToStepTop();
    window.addEventListener('scroll', scrollToStepTop);
    setTimeout(removeScrollListener, 1000);
  });
  onUnmounted(removeScrollListener);
}
