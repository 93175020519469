
























import { PropType, defineComponent } from '@vue/composition-api';
import GcButton from '../components/primitives/GcButton.vue';
import GcImage from '../components/primitives/GcImage.vue';
import RestoreAlert from '../components/RestoreAlert.vue';

export default defineComponent({
  components: {
    GcButton,
    GcImage,
    RestoreAlert,
  },
  props: {
    messages: {
      required: true,
      type: Array as PropType<string[]>,
    },
  },
  setup() {
    const baseUrl = process.env.VUE_APP_BASE_URL;

    return {
      baseUrl,
    };
  },
});
