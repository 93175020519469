<template>
  <gc-button icon outlined small color="info" class="tw-ml-2" @click="onClick"
    ><font-awesome-icon :icon="['fas', 'question']"
  /></gc-button>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import GcButton from './primitives/GcButton.vue';

export default defineComponent({
  components: { GcButton },
  setup(_, { emit }) {
    function onClick() {
      emit('click');
    }

    return {
      onClick,
    };
  },
});
</script>
