import { StepId } from '../../consts/assistant-steps';
import { FormFieldType } from '../../consts/input-types';
import {
  FormMultipleChoiceConfig,
  StepDefinition,
} from '../../interfaces/step-definition';
import component from './Topic.vue';

const config: StepDefinition = {
  component,
  fields: [
    {
      config: {
        columns: 3,
        options: [
          {
            icon: 'user-headset',
            value: 'consultation',
            visibilityCondition: context => {
              return context.buildingType.buildingType.includes('residental');
            },
          },
          {
            icon: 'user-headset',
            value: 'technicalConsultation',
            visibilityCondition: context =>
              context.buildingType.buildingType.includes('notResidental'),
          },
          {
            icon: 'tools',
            value: 'modernisation',
          },
          {
            icon: 'house',
            value: 'newBuilding',
          },
        ],
        singleAnswer: true,
        verticalOptionLayout: true,
      } as FormMultipleChoiceConfig,
      name: 'topic',
      required: true,
      type: FormFieldType.MultipleChoice,
    },
  ],
  id: StepId.TOPIC,
};

export default config;
