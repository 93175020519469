























import { defineComponent } from '@vue/composition-api';
import GcButton from './primitives/GcButton.vue';
import GcAlert from './primitives/GcAlert.vue';

export default defineComponent({
  components: {
    GcAlert,
    GcButton,
  },
  props: {
    hasPersistedState: {
      default: false,
      type: Boolean,
    },
  },
});
