import { NEXT, PREV, SUBMIT } from '../../consts/assistant-state-events';

export function useBaseTransitions(
  emit: (event: string, ...args: any[]) => void,
): {
  [key: string]: () => void;
} {
  const onNext = () => {
    emit('transition', {
      event: NEXT,
    });
  };

  const onPrev = () => {
    emit('transition', {
      event: PREV,
    });
  };

  const onSubmit = () => {
    emit('transition', {
      event: SUBMIT,
    });
  };

  return {
    onNext,
    onPrev,
    onSubmit,
  };
}
