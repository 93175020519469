var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gc-multiple-choice"},[_c('gc-row',_vm._l((_vm.filteredOptions),function(option){return _c('gc-col',{key:option.value,staticClass:"py-0 d-flex",class:{ 'mb-2': !_vm.isMd && _vm.k < _vm.options.length - 1 },attrs:{"cols":_vm.isMd ? 12 / _vm.columns : 12}},[_c('div',{staticClass:"gc-multiple-choice-option tw-w-full tw-p-5 tw-mb-2 tw-flex tw-items-center tw-border-2 tw-transition-all tw-duration-150 tw-ease-in-out tw-cursor-pointer tw-bg-white group",class:{
          'tw-border-primary': _vm.value.includes(option.value),
          'tw-border-white hover:tw-bg-secondary hover:tw-border-secondary': !_vm.value.includes(
            option.value
          ),
          'tw-justify-center': _vm.verticalOptionLayout,
        },attrs:{"data-test-id":"option"},on:{"click":function($event){return _vm.selectOption(option.value)}}},[_c('div',{staticClass:"tw-flex tw-justify-start tw-items-center",class:{ 'tw-flex-col-reverse': _vm.verticalOptionLayout }},[_c('div',[_vm._t("customOption",function(){return [_c('div',{staticClass:"tw-flex tw-justify-start tw-items-center",class:{ 'tw-flex-col': _vm.verticalOptionLayout }},[(option.icon)?_c('div',{class:{
                    'tw-mb-4': _vm.verticalOptionLayout,
                    'tw-mr-2': !_vm.verticalOptionLayout,
                  }},[_c('font-awesome-icon',{staticClass:"tw-text-primary group-hover:tw-text-red-200",attrs:{"icon":['fad', option.icon],"size":"2x"}})],1):_vm._e(),_c('div',{staticClass:"gc-multiple-choice-option__content",class:{ 'tw-text-center': _vm.verticalOptionLayout }},[_c('span',{staticClass:"tw-font-bold"},[_vm._v(" "+_vm._s(_vm.$t( _vm.getOptionLabelString( _vm.step.id, _vm.fieldName, option.value ) ))+" ")]),(
                      _vm.$te(
                        _vm.getOptionDescriptionString(
                          _vm.step.id,
                          _vm.fieldName,
                          option.value
                        )
                      )
                    )?_c('small',{staticClass:"tw-block tw-text-opacity-75"},[_vm._v(" "+_vm._s(_vm.$t( _vm.getOptionDescriptionString( _vm.step.id, _vm.fieldName, option.value ) ))+" ")]):_vm._e()])])]},{"option":option})],2)])])])}),1),(_vm.enableCustomOption)?_c('div',{staticClass:"tw-flex gc-mutliple-choice-option tw-px-0 tw-pt-2"},[_c('gc-text-field',{staticClass:"tw-mb-0",attrs:{"label":_vm.$t("customOption"),"dense":"","outlined":""},on:{"focus":function($event){return _vm.handleInputFocus()}},model:{value:(_vm.customOption),callback:function ($$v) {_vm.customOption=$$v},expression:"customOption"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }