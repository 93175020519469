







































import { defineComponent } from '@vue/composition-api';
import GcDivider from '../components/primitives/GcDivider.vue';
import { StepId } from '../consts/assistant-steps';

export default defineComponent({
  components: {
    GcDivider,
  },
  props: {
    currentStep: {
      required: true,
      type: Number,
    },
    showLabels: {
      default: false,
      type: Boolean,
    },
    steps: {
      required: true,
      type: Array as () => Array<string | StepId>,
    },
  },
  setup(_, { emit }) {
    function backToStep(id: string) {
      emit('transition', {
        event: `BACK_TO_${id.toUpperCase()}`,
      });
    }

    return {
      backToStep,
    };
  },
});
