
import { VBtn } from 'vuetify/lib';
import { getTenantConfig } from '../../lib/tenants';

export default VBtn.extend({
  props: {
    elevation: {
      default: 0,
      type: [String, Number],
    },
    rounded: {
      default: !!getTenantConfig().styles.rounded,
      type: [Boolean, String],
    },
  },
});
