import { StepId } from '../../consts/assistant-steps';
import { FormFieldType } from '../../consts/input-types';
import {
  FormMultipleChoiceConfig,
  StepDefinition,
} from '../../interfaces/step-definition';
import component from './BuildingType.vue';

const config: StepDefinition = {
  component,
  fields: [
    {
      config: {
        columns: 2,
        options: [
          { icon: 'house', value: 'residental' },
          {
            icon: 'building',
            value: 'notResidental',
          },
        ],
        singleAnswer: true,
        verticalOptionLayout: true,
      } as FormMultipleChoiceConfig,
      name: 'buildingType',
      required: true,
      type: FormFieldType.MultipleChoice,
    },
  ],
  id: StepId.BUILDING_TYPE,
};

export default config;
