export default {
  buildingType: {
    formFields: {
      buildingType: {
        options: {
          notResidental: {
            label: 'Nichtwohngebäude',
          },
          residental: {
            label: 'Wohngebäude',
          },
        },
      },
    },
    message1:
      'Guten Tag, mein Name ist Esra. Darf ich Ihnen helfen, passende Produkte zu finden – mit nur 3 einfachen Fragen?',
    message2: 'Um welchen Gebäudetyp handelt es sich bei Ihnen?',
    title: 'Gebäudetyp',
  },
  container: {
    formFields: {
      container: {
        options: {
          sundae: {
            label: 'Becher',
          },
          waffle: {
            label: 'Waffel',
          },
        },
      },
    },
    label: 'Welchen Behälter möchtest du?',
    title: 'Behälter',
  },
  done: {
    title: 'Fertig',
  },
  groups: {
    location: 'Standort',
  },
  loadingProducts: 'Produkte werden geladen...',
  location: {
    formFields: {
      address: {
        label: 'Adresse',
      },
      agree: {
        label: 'Ich stimme hiermit zu',
      },
      city: {
        label: 'Ort',
      },
      distance: {
        label: 'Entfernung',
      },
      gender: {
        label: 'Anrede',
        options: {
          divers: {
            label: 'Divers',
          },
          female: {
            label: 'Frau',
          },
          male: {
            label: 'Herr',
          },
        },
      },
      idPrepared: {
        label: 'Ich besitze einen Ausweis zur Verifizierung',
      },
      street: {
        label: 'Straße',
      },
      zipcode: {
        label: 'Postleitzahl',
      },
    },
    label: 'An welchem Standort möchten Sie Eis essen?',
    title: 'Adresse',
  },
  locationOverview: {
    label: 'Übersicht der Standorte',
    title: 'Standorte',
  },
  media: {
    formFields: {
      files: {
        label: 'Dateien auswählen',
      },
      mediaDescription: {
        label: `**Was sie hochladen können:**
        Sie können ein Bild von einem Eis oder auch von sich hochladen.
        - Eiscreme
        - Portrait`,
      },
    },
    label: 'Möchtest du eine Vorlage für dein Eis hochladen?',
    title: 'Vorlage',
  },
  overview: {
    label: 'Übersicht Ihrer Eingaben',
    title: 'Übersicht',
  },
  paymentInfo: {
    formFields: {
      creditCardNumber: {
        label: 'Kreditkartennummer',
      },
      creditCardOwner: {
        label: 'Besitzer der Kreditkarte',
      },
    },
    label: 'Bitte geben Sie ihre Zahlungsinformationen ein:',
    title: 'Zahlungsinformationen',
  },
  paymentMethod: {
    formFields: {
      paymentMethod: {
        options: {
          card: {
            label: 'Kreditkarte',
          },
          cash: {
            label: 'Bargeld',
          },
        },
      },
    },
    label: 'Wie möchten Sie bezahlen?',
    title: 'Zahlungsmittel',
  },
  products: {
    label: 'Wir haben diese Produkte für Sie gefunden:',
    noProductsFound: 'Es wurden keine Produkte für Ihre Auswahl gefunden.',
    title: 'Produkte',
  },
  start: {
    label:
      'Darf Ihnen unser Assistent helfen, individuelle Produkte für Sie zu finden?',
    title: 'Start',
  },
  startAssistant: 'Assistenten starten',
  stopAssistant: 'Assistenten beenden',
  taste: {
    formFields: {
      taste: {
        options: {
          chocolate: {
            description:
              'Schokolade ist eine sehr leckere Sorte und du solltest sie unbedingt probieren.',
            label: 'Schokolade',
          },
          strawberry: {
            description:
              'Erdbeere ist eine sehr leckere Sorte und du solltest sie unbedingt probieren.',
            label: 'Erdbeere',
          },
          vanilla: {
            description:
              'Vanille ist eine sehr leckere Sorte und du solltest sie unbedingt probieren.',
            label: 'Vanille',
          },
          woodruff: {
            description:
              'Waldmeister ist eine sehr leckere Sorte und du solltest sie unbedingt probieren.',
            label: 'Waldmeister',
          },
        },
      },
    },
    label: 'Welche Geschmacksrichtungen möchtest du?',
    title: 'Geschmack',
  },
  topic: {
    formFields: {
      topic: {
        label: 'Thema',
        options: {
          consultation: {
            label: 'Beratung/Sprechstunde',
          },
          modernisation: {
            label: 'Modernisierung und Erweiterung',
          },
          newBuilding: {
            label: 'Neubau',
          },
          technicalConsultation: {
            label: 'Technische Beratung',
          },
        },
      },
    },
    message1: 'Für welches Thema interessieren Sie sich?',
    title: 'Thema',
  },

  topping: {
    formFields: {
      topping: {
        options: {
          blueberries: {
            label: 'Blaubeeren',
          },
          cherries: {
            label: 'Kirschen',
          },
          pistachios: {
            label: 'Pistazien',
          },
          raspberries: {
            label: 'Himbeeren',
          },
        },
      },
    },
    label: 'Welches Topping möchtest du?',
    title: 'Topping',
  },
};
