import Vue from 'vue';
import VueI18n from 'vue-i18n';
import de from '../lang/de-DE';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'de',
  messages: {
    ...de,
  },
});

export default i18n;
