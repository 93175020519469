import { StepId } from '../../consts/assistant-steps';
import { StepDefinition } from '../../interfaces/step-definition';
import component from './Products.vue';

const config: StepDefinition = {
  component,
  id: StepId.PRODUCTS,
};

export default config;
