<template>
  <div
    class="gc-multiple-choice-checkbox tw-text-center"
    :class="[
      selected ? 'tw-bg-primary tw-text-white' : 'tw-bg-gray-300',
      singleAnswer ? 'tw-rounded-full' : 'tw-rounded',
    ]"
  >
    <font-awesome-icon v-if="selected" :icon="['fas', 'check']" />
  </div>
</template>

<script>
export default {
  props: {
    selected: {
      default: false,
      type: Boolean,
    },
    singleAnswer: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style>
.gc-multiple-choice-checkbox {
  flex-shrink: 0;
  display: flex;
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  color: white;
  font-size: 75%;
  align-items: center;
  justify-content: center;
}
</style>
