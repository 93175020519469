import { PropType } from '@vue/composition-api';
import { AssistantContext } from '../../config/context';
import { StepId } from '../../consts/assistant-steps';
import { StepDefinition } from '../../interfaces/step-definition';

export const stepComponentProps = {
  assistantContext: {
    default: () => ({}),
    required: true,
    type: Object as PropType<AssistantContext>,
  },
  step: {
    required: true,
    type: Object as PropType<StepDefinition>,
  },
  steps: {
    required: true,
    type: Array as PropType<StepDefinition[]>,
  },
};

export function getStep(id: string | StepId, steps: StepDefinition[]) {
  return steps.find(s => id === s.id);
}

export function getStepIndex(id: string | StepId, steps: StepDefinition[]) {
  return steps.findIndex(s => id === s.id);
}

export function getStepByIndex(index: number, steps: StepDefinition[]) {
  return steps[index];
}

export function getInitialStep(steps: StepDefinition[]) {
  return (steps.find(step => step.isInitial) || steps[0])?.id || undefined;
}

export function getContactFormStep(steps: StepDefinition[]) {
  return steps.find(step => step.isContactForm)?.id || undefined;
}
