































import { PropType, defineComponent, ref } from '@vue/composition-api';
import SkipMessage from './SkipMessage.vue';
import GcProgressCircular from '../components/primitives/GcProgressCircular.vue';
import AssistantStepsOverview from '../components/AssistantStepsOverview.vue';
import AssistantHeader from '../components/AssistantHeader.vue';
import StartScreen from '../components/StartScreen.vue';
import { StepDefinition } from '../interfaces/step-definition';
import { AssistantMachine } from '../interfaces/assistant';

export default defineComponent({
  components: {
    AssistantHeader,
    AssistantStepsOverview,
    GcProgressCircular,
    SkipMessage,
    StartScreen,
  },
  props: {
    machine: {
      required: true,
      type: Object as PropType<AssistantMachine>,
    },
    steps: {
      required: true,
      type: Array as PropType<StepDefinition[]>,
    },
  },
  setup(props) {
    const { final, send, currentStepIndex } = props.machine;
    const assistantStarted = ref(false);

    const onTransition = (transition: { event: string; payload?: any }) => {
      send(transition.event, transition.payload);
    };

    const overviewSteps = props.steps.map(step => step?.id);

    return {
      assistantStarted,
      currentStepIndex,
      final,
      onTransition,
      overviewSteps,
    };
  },
});
