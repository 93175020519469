













import { defineComponent } from '@vue/composition-api';
import { useStepTopScroller } from '../lib/ui-helper/scroll-to-step';

export default defineComponent({
  props: {
    label: {
      default: '',
      type: String,
    },
    valid: {
      default: true,
      type: Boolean,
    },
  },
  setup() {
    useStepTopScroller();

    return {};
  },
});
