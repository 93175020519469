









import { defineComponent } from '@vue/composition-api';
import GcButton from '../components/primitives/GcButton.vue';
import RestoreAlert from '../components/RestoreAlert.vue';

export default defineComponent({
  components: {
    GcButton,
    RestoreAlert,
  },
  setup(_, { emit }) {
    function startAssistant() {
      emit('start');
    }

    return {
      startAssistant,
    };
  },
});
