

























import { defineComponent, onMounted, ref } from '@vue/composition-api';
import GcProgressCircular from '../../components/primitives/GcProgressCircular.vue';
import { loadProducts } from '../../lib/data/products';
import { stepComponentProps } from '../../lib/steps/helper';
import i18n from '../../plugins/i18n';
import Product from './Product.vue';

export default defineComponent({
  components: {
    GcProgressCircular,
    Product,
  },
  props: {
    ...stepComponentProps,
  },
  setup(props) {
    const loading = ref(false);
    const products = ref<string[]>([]);

    const filterTags = [
      ...props.assistantContext.buildingType.buildingType.map((val: string) =>
        i18n
          .t(`buildingType.formFields.buildingType.options.${val}.label`)
          .toString()
          .toLowerCase(),
      ),
      ...props.assistantContext.topic.topic.map((val: string) =>
        i18n
          .t(`topic.formFields.topic.options.${val}.label`)
          .toString()
          .toLowerCase(),
      ),
    ];

    onMounted(async () => {
      loading.value = true;
      try {
        products.value = await loadProducts(filterTags);
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    });
    return {
      loading,
      products,
    };
  },
});
