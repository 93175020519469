// xstate Events
export const NEXT = 'NEXT';
export const PREV = 'PREV';
export const RESET = 'RESET';
export const SUBMIT = 'SUBMIT';
export const SUBMIT_SUCCESS = 'SUBMIT_SUCCESS';
export const SUBMIT_ERROR = 'SUBMIT_ERROR';
export const JUMP_TO_CONTACTFORM = 'JUMP_TO_CONTACTFORM';
export const ADD_GROUP_ITEM = 'ADD_GROUP_ITEM';
export const EDIT_GROUP_ITEM = 'EDIT_GROUP_ITEM';
export const REMOVE_GROUP_ITEM = 'REMOVE_GROUP_ITEM';
export const CANCEL_GROUP_ITEM = 'CANCEL_GROUP_ITEM';
