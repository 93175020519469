import axios, { AxiosResponse } from 'axios';

interface AuthResponse {
  access_token: string;
}

interface ProductResponse {
  elements: Array<{
    id: string;
  }>;
}

export interface ProductSearchFilter {
  field: string;
  type: string;
  value: string;
  operator?: string;
  queries?: Array<any>;
}

async function fetchToken() {
  const authApi = `${process.env.VUE_APP_STORE_URL}/api/oauth/token`;
  const response: AxiosResponse<AuthResponse> = await axios.post(authApi, {
    // eslint-disable-next-line @typescript-eslint/camelcase
    client_id: process.env.VUE_APP_CLIENT_ID,
    // eslint-disable-next-line @typescript-eslint/camelcase
    client_secret: process.env.VUE_APP_CLIENT_SECRET,
    // eslint-disable-next-line @typescript-eslint/camelcase
    grant_type: 'client_credentials',
  });
  return response.data?.access_token;
}

export async function loadProducts(filterTags: string[]): Promise<string[]> {
  // load ids from shopware api here...
  const ids: Array<string> = [];

  const token = await fetchToken();
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  axios.defaults.headers.common['sw-access-key'] = process.env.VUE_APP_SW_ACCESS_KEY;
  const searchApi = `${process.env.VUE_APP_STORE_URL}/store-api/product`;
  const response: AxiosResponse<ProductResponse> = await axios.post(searchApi, {
    associations: {
      tags: {},
    },
    filter: [
      {
        type: 'multi',
        operator: 'AND',
        queries: [
          {
            operator: 'OR',
            queries: filterTags.map(t => ({
              field: 'tags.name',
              type: 'equals',
              value: t,
            })),
            type: 'multi',
          },
          // do not load variants
          {
            type: 'equals',
            field: 'parentId',
            value: null,
          }
        ]
      },
    ],
    includes: {
      product: ['id', 'tags'],
    },
  });
  response.data.elements
    .filter((product: Record<string, any>) => {
      return (
        product.tags.filter((tag: Record<string, any>) => {
          return filterTags.includes((tag.name as string).toLowerCase());
        }).length === filterTags.length
      );
    })
    .forEach((product: Record<string, any>) => {
      ids.push(product.id);
    });
  const products: string[] = [];

  let productUrl =
    `${process.env.VUE_APP_STORE_URL}/gc/product-tile/content?tileSize=standard&productLinkTarget=blank`;

  /**
   * If shadow DOM is not supported require content with inlined styles as fallback
   */
  if (!HTMLElement.prototype.attachShadow) {
    productUrl = `${productUrl}&inline=true`
  }

  await Promise.all(
    ids.map(async productId => {
      const template = await axios.get(
        `${productUrl}&productId=${productId}`,
        {
          headers: {
            Authorization: 'Basic Z3JlZW5wb3J0YWw6Z11nN2cpcisjdnBHMltKPkpHW3g=',
          },
        },
      );
      products.push(template.data);
    }),
  );

  return products;
}
